import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode, faEngineWarning } from "@fortawesome/pro-regular-svg-icons";
import {
  Drawer,
  Typography,
  IconButton,
} from "@material-ui/core";

import { useStyles } from "./EntityProvisionButton.style";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

import { FindEntity } from "../../../state/slices/entities";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import EntityQRCode from "./EntityQRCode";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";
import {selectEntityById} from "../../../state/slices/CoreEntity";

const EntityProvisionButton = ({ entityID, isBeingListened, ...props }) => {
  const theme = useTheme();
  const useCoreEntitySlice = useCoreEntityContext();
  const classes = useStyles();
  const entity = useSelector((state) => useCoreEntitySlice ? selectEntityById(state, entityID) : FindEntity(state.entities.EntityList, entityID), shallowEqual);
  const { textConfirmation } = useConfirmationDialog();

  const [drawerState, setDrawerState] = useState(false);

  async function ProvisionDeviceConfirmation() {
    let provisionMessage = (
      <>
        <FontAwesomeIcon
          className={clsx(["animate__animated", "animate__tada"])}
          icon={faEngineWarning}
          size="4x"
          style={{
            color: theme.palette.error.main,
            float: "left",
            alignItems: "baseline",
            marginRight: 15,
          }}
        ></FontAwesomeIcon>
        <Typography
          variant="subtitle1"
          style={{ color: theme.palette.error.main }}
        >
          This will reset the device security key and secret. A working device
          will need to be reprovisioned if you continue.
        </Typography>
      </>
    );
    let continueProvision = await textConfirmation({
      title: `PROVISION ${entity.name.toUpperCase()}?`,
      message: provisionMessage,
      textConfirmation: entity.name.toUpperCase(),
      buttons: [
        { name: "Yes", color: "secondary" },
        { name: "Cancel", color: theme.palette.text.disabled },
      ],
    });
    return continueProvision;
  }

  useEffect(() => {
    //if user or entity changes
  }, [entity.state]);
  return (
    <>
      <IconButton
        {...props}
        title={`Provision ${entity.name}`}
        aria-label={`Provision ${entity.name}`}
        disabled={entity.state != null || !isBeingListened}
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          if (await ProvisionDeviceConfirmation()) setDrawerState(true);
        }}
      >
        <FontAwesomeIcon icon={faQrcode} size="1x" />
      </IconButton>
      <Drawer
        classes={{ paper: classes.drawerPaper }}
        open={drawerState}
        anchor="top"
      >
        <Typography style={{ padding: 20 }}>
          <EntityQRCode
            entityID={entity.entityid}
            onClose={() => setDrawerState(false)}
          ></EntityQRCode>
        </Typography>
      </Drawer>
    </>
  );
};

EntityProvisionButton.defaultProps = { entityID: null, isBeingListened: true };
EntityProvisionButton.propTypes = { entityID: PropTypes.string, isBeingListened: PropTypes.bool};

export default EntityProvisionButton;
