import React, { useState } from "react";
import PropTypes from "prop-types";

/* Components */
import BNRTotals from "../../../BNRTotals";
import NonResetCounts from "../../../NonResetCounts";
import DeviceLogs from "../../../DeviceLogs";
import MuiAlert from "@material-ui/lab/Alert";
import MuiAlertTitle from "@material-ui/lab/AlertTitle";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Zoom,
} from "@material-ui/core";
import EntityErrorMessages from "../../../Entities/Modules/EntityErrorMessages";

/* Icons */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle as warningIcon } from "@fortawesome/pro-regular-svg-icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EntityIconBar from "../../../Entities/Modules/EntityIconBar";

/* State */
import { useSelector } from "react-redux";
import { FindEntity } from "../../../../state/slices/entities";

/* Style */
import { useStyles } from "./styles";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

/* Contants */
import { ENTITY_TYPE, DEVICE_MODE } from "../../../../constants";

/* Utilities */
import _ from "lodash";
import DeviceVersionInfo from "./DeviceVersionInfo";
import { useListening } from "../../../../hooks/useDeviceListener/useDeviceListenerContext";
import { useCoreEntityContext } from "../../../../hooks/useCoreEntitySlice";
import {selectEntityById} from "../../../../state/slices/CoreEntity";


export const EntityDetailDetails = ({ entityId }) => {
  const theme = useTheme();
  const [alertClose, setAlertClose] = useState(false);
  const useCoreEntitySlice = useCoreEntityContext();

  const entity = useSelector(
    (state) =>useCoreEntitySlice ? selectEntityById(state, entityId) : FindEntity(state.entities?.EntityList ?? [], entityId),
    (one, two) => JSON.stringify(one) === JSON.stringify(two)
  );

  const [expandBNR, setExpandBNR] = useState(true);
  const [expandNRC, setExpandNRC] = useState(true);
  const [expandLogs, setExpandLogs] = useState(true);
  const [expandInfo, setExpandInfo] = useState(true);
  const [expandVid, setExpandVid] = useState(true);
  const { isBeingListened } = useListening();

  const toggleBNR = () => {
    setExpandBNR(!expandBNR);
  };
  const toggleNRC = () => {
    setExpandNRC(!expandNRC);
  };
  const toggleLogs = () => {
    setExpandLogs(!expandLogs);
  };
  const toggleVid = () => {
    setExpandVid(!expandVid);
  };
  const toggleInfo = () => {
    setExpandInfo(!expandInfo);
  };

  const deviceMode =
    _.find(entity?.settings, { name: "devicemode" })?.value || null;
  // for testing, since we are stubbing data, allow us to pass in different
  // entitydata object. if the right properties aren't on entityData, use this constant
  const classes = useStyles();
  return (
    <>
      <div style={{ paddingTop: 10 }}></div>
      {entity?.typeid == ENTITY_TYPE.Device && (
        <>
          {entity?.state == null && !alertClose && (
            <>
              <Zoom in={true} style={{ marginBottom: 10 }}>
              <MuiAlert
                icon={
                  <FontAwesomeIcon
                    style={{ fontSize: "20pt", color: "rgb(150,0,13)" }}
                    icon={warningIcon}
                  ></FontAwesomeIcon>
                }
                severity="error"
                onClose={() => {
                  setAlertClose(true);
                }}
              >
                <MuiAlertTitle
                  className={clsx("animate__animated animate__flash")}
                >
                  DEVICE NOT RESPONDING
                </MuiAlertTitle>
                Unable to communicate with this device. Please check the
                device power and network connection.
              </MuiAlert>
            </Zoom>
            </>
          )}
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridItem}
            >
              <Accordion expanded={expandInfo}>
                <AccordionSummary
                  onClick={toggleInfo}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.heading}>
                    Information
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      style={{
                        minWidth: 210,
                        textAlign: "center",
                        paddingRight: 40,
                      }}
                    >
                      <DeviceVersionInfo entityID={entityId} />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <span>
                        {deviceMode.toLowerCase() ===
                          DEVICE_MODE.DOOR.toLowerCase() ||
                        deviceMode.toLowerCase() ===
                          DEVICE_MODE.HOTEL_INTERFACE.toLowerCase()
                          ? "This is a "
                          : "This "}
                        <span
                          style={{
                            fontWeight: "bold",
                            color: theme.palette.primary.light,
                          }}
                        >
                          {deviceMode.toLowerCase() ===
                          DEVICE_MODE.KIOSK.toLowerCase()
                            ? "pay on foot"
                            : deviceMode.toLowerCase()}
                        </span>
                        {" device "}
                        {
                          {
                            Entry: (
                              <span>
                                moves parkers in
                                {entity.details.destination == null
                                  ? " to the facility"
                                  : " to " + entity.details.destination}
                                .
                              </span>
                            ),
                            Exit: (
                              <span>
                                moves parkers out
                                {entity.details.destination == null
                                  ? " of the facility"
                                  : " to " + entity.details.destination}
                                .
                              </span>
                            ),
                            Kiosk: (
                              <span>
                                moves parkers out
                                {entity.details.destination == null
                                  ? " of the facility"
                                  : " to " + entity.details.destination}
                                .
                              </span>
                            ),
                            Diagnostic: (
                              <span>
                                is in diagnostic mode at
                                {entity.details.destination == null
                                  ? " the facility"
                                  : entity.details.destination}
                                .
                              </span>
                            ),
                          }[deviceMode]
                        }
                      </span>
                      <EntityErrorMessages
                        entityID={entityId}
                        style={{ marginRight: 5 }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
                <AccordionActions>
                  <EntityIconBar entityID={entityId} hideType isBeingListened={isBeingListened(entityId)} />
                </AccordionActions>
              </Accordion>
            </Grid>
            {deviceMode.toLowerCase() !=
              DEVICE_MODE.HOTEL_INTERFACE.toLowerCase() && (
              <>
                <Grid item xs={12} sm={6} md={4} className={clsx("bnr-totals", "panel")}>
                  <Accordion expanded={expandBNR}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={toggleBNR}
                    >
                      <Typography className={classes.heading}>
                        BNR Totals
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <BNRTotals entityid={entityId} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className={clsx("counts", "panel")}>
                  {deviceMode.toLowerCase() !==
                    DEVICE_MODE.KIOSK.toLowerCase() && (
                    <Accordion expanded={expandNRC}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        onClick={toggleNRC}
                      >
                        <Typography className={classes.heading}>
                          Counts
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <NonResetCounts entityid={entityId} />
                      </AccordionDetails>
                    </Accordion>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6} md={4} className={clsx("logs", "panel")}>
              <Accordion expanded={expandLogs}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={toggleLogs}
                >
                  <Typography className={classes.heading}>Logs</Typography>
                  <Typography className={classes.titlesubheading}> (UTC date)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DeviceLogs entityId={entityId} />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </>
      )}
      {entity?.typeid == ENTITY_TYPE.Facility && <></>}
    </>
  );
};

EntityDetailDetails.propTypes = {
  entityId: PropTypes.string,
};

export default EntityDetailDetails;
