import React from "react";
import PropTypes from "prop-types";

/* Components */
import { Typography, Box, Grid } from "@material-ui/core";

/* Icons */
import A1Logo from "../../../../../assets/logos/a1-logo-a1-cropped.svg";

/* State */
import { useSelector } from "react-redux";
import { FindEntity } from "../../../../../state/slices/entities";

/* Style */
import clsx from "clsx";
import { useStyles } from "./styles";
import {useCoreEntityContext} from "../../../../../hooks/useCoreEntitySlice";
import {selectEntityById} from "../../../../../state/slices/CoreEntity";


const DeviceVersionInfo = ({ entityID }) => {
  const classes = useStyles();
  const useCoreEntitySlice = useCoreEntityContext();

  const {serial, entityVersionInfo} = useSelector((state) => {
    const entity = useCoreEntitySlice ? selectEntityById(state, entityID) : FindEntity(state.entities?.EntityList ?? [], entityID);

    return {
      serial: entity?.state?.SerialNumber ?? "Unknown",
      entityVersionInfo: {
        version: entity?.state?.Version ?? "Unknown"
      },
    };
  })

  return (
    <Box className="root">
      <div className={classes.versionContainer}>
        <img
          className={classes.newLogoSvg}
          alt="company logo"
          src={A1Logo}
          width={200}
        />
        <div
          className={clsx([
            "version-info",
            "animate__animated",
            "animate__bounceIn",
          ])}
        >
          <span className="version-text">{entityVersionInfo.version}</span>
        </div>
      </div>
      <Grid container direction="column" className={classes.versionSubTextGrid}>
        <Grid item>
          <Typography
            className={classes.versionSubTextHeader}
            variant="body2"
            color="primary"
          >
            Current Running Version
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={classes.versionSubText}
            variant="overline"
            data-testid="running-version"
          >
            {entityVersionInfo.version}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={classes.deviceSerialHeader}
            variant="body2"
            color="primary"
          >
            Serial Number
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className={clsx("device-serial", classes.deviceSerial)}
            variant="overline"
            data-testid="serial-number"
          >
            {serial}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

DeviceVersionInfo.propTypes = {
  entityID: PropTypes.string.isRequired,
};

export default DeviceVersionInfo;
