import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEngineWarning } from "@fortawesome/pro-duotone-svg-icons";
import { Button, Typography } from "@material-ui/core";

import { useStyles } from "./EntityDeleteButton.styles";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

import * as c from "../../../constants";
import _ from "lodash";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";

import { FindEntity, entityDelete } from "../../../state/slices/entities";
import {selectEntityById, deleteEntity, selectContextEntity} from "../../../state/slices/CoreEntity";
import { useFlags } from "launchdarkly-react-client-sdk";

import useHasPermissions from "../../../hooks/useHasPermissions";
import apiClient from "../../../auth/apiClient";
import ReversingLaneService from "../../../services/ReversingLaneService";
import {useCoreEntityContext} from "../../../hooks/useCoreEntitySlice";

const reversingLaneService = new ReversingLaneService(apiClient);

const EntityDeleteButton = ({ entityID, onSuccess, isBeingListened, ...props }) => {
  const useCoreEntitySlice = useCoreEntityContext();
  const theme = useTheme();
  const entity =
    useSelector(
      (state) => useCoreEntitySlice ? selectEntityById(state, entityID) : FindEntity(state.entities?.EntityList ?? [], entityID),
      shallowEqual
    ) ?? {};
  const dispatch = useDispatch();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { showDialog, textConfirmation } = useConfirmationDialog();
  const currentContext = useSelector((state) =>useCoreEntitySlice ? selectContextEntity(state) : state.entities.Context);

  const { hasPermissions } = useHasPermissions();
  const deleteDevicePermission = hasPermissions([
    "overview.devices.deletedevice",
  ]);
  const isAdminUser = useSelector(state => state?.user?.isAdmin);
  const checkPermission = (type) => {
    switch (type) {
      case "Device":
        return deleteDevicePermission;
      case "ValetArea":
        return isAdminUser;
      default:
        return true;
    }
  };
  const isCurrentFacility = (entityType) => {
    switch (entityType) {
      case c.ENTITY_TYPE.Facility:
        return entity?.entityid == currentContext?.entityid;
      case c.ENTITY_TYPE.FacilityGroup:
        return entity?.entityid == currentContext?.entityid;
      case c.ENTITY_TYPE.ValetArea:
        return entity?.entityid == currentContext?.entityid;
      default:
        return false;
    }
  }

  const handleClick = async () => {
    const msgComponent = (
      <>
        <FontAwesomeIcon
          icon={faEngineWarning}
          size="4x"
          className={clsx(["animate__animated", "animate__tada"])}
          style={{
            color: theme.palette.error.main,
            float: "left",
            alignItems: "baseline",
            marginRight: 15,
          }}
        ></FontAwesomeIcon>
        <Typography
          variant="h6"
          style={{ fontWeight: "bold", color: theme.palette.error.main }}
        >
          This is a permanent action that cannot be undone.
        </Typography>
        <div style={{ clear: "both" }}></div>
      </>
    );
    let belongsToLane = false;
    if (entity?.typeid == c.ENTITY_TYPE.Device) {
      try {
        const responce = await reversingLaneService.getReversingLanesByDeviceID(entity?.entityid,);
        if (responce?.data?.length !== 0) {
          belongsToLane = true;
        }
      } catch (err) {
        enqueueSnackbar("Unable to retrieve reversing lanes for this entity", {
          variant: "error",
          tag: "FailedToFindReversingLaneForEntity"
        });
        return null;
      }
    }

    if (belongsToLane) {
      await showDialog({
        title: `This device cannot be deleted while paired as a reversing lane. Please delete the reversing lane pairing first.`,
        buttons: [
          { name: "OK", color: "primary" }
        ],
      });
      return;
    }

    let continueDelete = await textConfirmation({
      title: `DELETE ${entity.name.toUpperCase()}?`,
      message: msgComponent,
      textConfirmation: entity.name.toUpperCase(),
      buttons: [
        { name: "Yes", color: "secondary" },
        { name: "Cancel", color: theme.palette.text.disabled },
      ],
    });

    if (continueDelete) {
      var result = await dispatch(
        useCoreEntitySlice ?
          deleteEntity({
            path: getDeletePath(entity?.typeid),
            payload: {
              entityid: entity?.entityid,
              parententityid: entity?.parententityid,
            },
          })
          :
          entityDelete({
            path: getDeletePath(entity?.typeid),
            payload: {
              entityid: entity?.entityid,
              parententityid: entity?.parententityid,
            },
          })
      );

      if (result.error) {
        enqueueSnackbar("Could not delete " + entity.name, {
          variant: "error",
          tag: "FaileddeletingEntity",
        });
      } else {
        try {
          onSuccess();
        } catch (e) {
          //do nothing
        }
      }
    }
  };

  const getDeletePath = (entityType) => {
    switch (entityType) {
      case c.ENTITY_TYPE.Device:
        return "parkingdevice";
      case c.ENTITY_TYPE.Facility:
        return "facility";
      case c.ENTITY_TYPE.ValetArea:
        return "valetarea";
      default:
        return "entities/basic";
    }
  };

  return (
    <>
      {checkPermission(entity.type) && (
        <Button
          {...props}
          onClick={handleClick}
          disabled={isCurrentFacility(entity?.typeid) || entity.state != null || entity.children?.length > 0 || ( entity?.typeid == c.ENTITY_TYPE.Device && !isBeingListened)}
        >
          DELETE{" "}
          {{
            FacilityGroup: "Facility Group",
            ValetArea: "Area",
          }[entity.type] || entity.type}
        </Button>
      )}
    </>
  );
};

EntityDeleteButton.defaultProps = { entityID: null, onSuccess: undefined, isBeingListened: true};
EntityDeleteButton.propTypes = {
  entityID: PropTypes.string,
  onSuccess: PropTypes.func,
  isBeingListened: PropTypes.bool
};

export default EntityDeleteButton;
