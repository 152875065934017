import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useStyles } from "./styles";
import { Drawer, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import { Divider, Typography, Button, Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import * as c from "../../../../constants";
import _ from "lodash";

import { FindEntity } from "../../../../state/slices/entities";
import useHasPermissions from "../../../../hooks/useHasPermissions";
import SettingsForm from "../../../Forms/Settings";

import GenericEditor from "../../../Forms/Onboarding/DeviceEditor";
import FacilityEditor from "../../../Forms/Onboarding/FacilityEditor";
import AreaEditor from "../../../Forms/Onboarding/AreaEditor";
import ValetAreaEditor from "../../../Forms/Onboarding/ValetAreaEditor";
import clsx from "clsx";
import { fetchPeripheralsList } from "../../../../state/slices/peripherals";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import FacilityGroupEditor from "../../../Forms/Onboarding/FacilityGroupEditor";
import { FlagFormContextProvider } from "../../../Forms/Features/EnableFeatureFlags/flagformcontextprovider";
import {extractCommonSettings, extractTypeSpecificSettings} from "./SettingsUtil";
import {useCoreEntityContext} from "../../../../hooks/useCoreEntitySlice";
import {selectEntityById, selectParentEntity} from "../../../../state/slices/CoreEntity";


//TODO JonathanPayares Might have to do with overview Tree need to wait for org slice story
export const EntityDetailSettings = ({ entityId }) => {
  const useCoreEntitySlice = useCoreEntityContext();

  const classes = useStyles();

  const dispatch = useDispatch();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const currentUser = useCurrentUser();
  const { hasPermissions } = useHasPermissions();

  const [showEditDrawer, setShowEditDrawer] = useState(false);

  const entity = useSelector((state) => {
    const entity =useCoreEntitySlice ? selectEntityById(state,entityId) : FindEntity(state.entities?.EntityList ?? [], entityId);
    if (!entity) return {};
    return {
      entityid: entity.entityid,
      parententityid: entity.parententityid,
      typeid: entity.typeid,
      type: entity.type,
      name: entity.name,
      description: entity.details?.description,
    };
  }, shallowEqual);

  // TODO JonathanPayares simplify
  const commonSettings = useSelector((state) => {
    const entity = useCoreEntitySlice ? selectEntityById(state,entityId) : FindEntity(state.entities?.EntityList ?? [], entityId) ?? {};
    return extractCommonSettings(entity);
  }, (old, current) => JSON.stringify(old) === JSON.stringify(current));

  //TODO JonathanPayares simplify
  const commonSettingsParent = useSelector((state) => {
    const parentOfEntity =useCoreEntitySlice ? selectEntityById(state,entity.parententityid) :  FindEntity(state.entities?.EntityList ?? [],entity.parententityid);
    return extractCommonSettings(parentOfEntity);
  }, (old, current) => JSON.stringify(old) === JSON.stringify(current));

  //TODO JonathanPayares simplify
  const typeSpecificSettings = useSelector((state) => {
    const entity = useCoreEntitySlice ? selectEntityById(state,entityId) : FindEntity(state.entities?.EntityList ?? [], entityId) ?? {};
    return extractTypeSpecificSettings(entity);
  }, (old, current) => JSON.stringify(old) === JSON.stringify(current));

  //TODO JonathanPayares simplify
  const typeSpecificSettingsParent = useSelector((state) => {
    const parentOfEntity = useCoreEntitySlice ? selectParentEntity(entityId) :
      FindEntity(state.entities?.EntityList ?? [], entity.parententityid) ?? {};
    return extractTypeSpecificSettings(parentOfEntity);
    }, (old, current) => JSON.stringify(old) === JSON.stringify(current));

  //TODO JonathanPayares SIMPLIFY
  const EditDeviceDemographicsPermission = hasPermissions(
    ["overview.devices.editdemographics"],
    false,
    entityId
  );
  const EditFacilityDemographicsPermission = hasPermissions(
    ["overview.facilities.editdemographics"],
    false,
    entityId
  );
  const EditValetAreaDemographicsPermission = hasPermissions(
    ["overview.valetarea.editdemographics"],
    false,
    entityId
  );
  const addAreaPermission = hasPermissions(
    ["overview.area.addarea"],
    false,
    entityId
  );
  const EditSettingsPermission = () => {
    switch (entity.typeid) {
      case c.ENTITY_TYPE.FacilityGroup:
        // Might need to replace with permissions for FG in later phase
        // Currently no permissions for FG.
        return hasPermissions(
          [
            "overview.facilities.editsettings"
          ],
          false,
          entityId
        );

      case c.ENTITY_TYPE.Facility:
        return hasPermissions(
          [
            "overview.facilities.editsettings"
          ],
          false,
          entityId
        );

      case c.ENTITY_TYPE.Device:
        return hasPermissions(
          [
            "overview.devices.editsettings"
          ],
          false,
          entityId
        );
      case c.ENTITY_TYPE.ValetArea:
        return hasPermissions(
          [
            "overview.valetarea.editsettings"
          ],
          false,
          entityId
        );
    }
  };
  //TODO END JonathanPayares SIMPLIFY

  const toggleShowEditor = () => {
    setShowEditDrawer(!showEditDrawer);
  };

  const closeDrawer = () => {
    setShowEditDrawer(false);
  };

  const closeDrawerAndExit = () => {
    setShowEditDrawer(false);
  };

  const drawerContent = () => {
    if (entity.typeid == c.ENTITY_TYPE.Facility) {
      return (
        <div style={{ padding: 10 }}>
          <FacilityEditor
            entityID={entity.entityid}
            parentEntityID={entity.parententityid}
            onCancel={closeDrawer}
            onSubmitComplete={closeDrawer}
            onDeleteComplete={closeDrawerAndExit}
          />
        </div>
      );
    } else if (entity.typeid == c.ENTITY_TYPE.ValetArea) {
      return (
        <div style={{ padding: 10 }}>
          <ValetAreaEditor
            entityID={entity.entityid}
            parentEntityID={entity.parententityid}
            onCancel={closeDrawer}
            onSubmitComplete={closeDrawer}
            onDeleteComplete={closeDrawerAndExit}
          />
        </div>
      );
    } else if (entity.typeid == c.ENTITY_TYPE.FacilityGroup) {
      return (
        <div style={{ padding: 10 }}>
          <FacilityGroupEditor
            entityID={entity.entityid}
            parentEntityID={entity.parententityid}
            onCancel={closeDrawer}
            onSubmitComplete={closeDrawer}
            onDeleteComplete={closeDrawerAndExit}
          />
        </div>
      );
    } else if (entity.typeid == c.ENTITY_TYPE.Area) {
      return (
        <div style={{ padding: 10 }}>
          <AreaEditor
            entityID={entity.entityid}
            parentEntityID={entity.parententityid}
            onSubmitComplete={closeDrawer}
            onCancel={closeDrawer}
          />
        </div>
      );
    } else {
      return (
        <>
          <GenericEditor
            entityID={entity.entityid}
            parentEntityID={entity.parententityid}
            onCancel={closeDrawer}
            onSaveComplete={closeDrawer}
            onDeleteComplete={closeDrawerAndExit}
          />
        </>
      );
    }
  };

  useEffect(() => {
    if (entity?.typeid === c.ENTITY_TYPE.Device) {
      dispatch(fetchPeripheralsList(entityId));
    }
  }, [entityId, entity.typeid, dispatch]);

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          className={classes.root}
          style={{ paddingTop: 10, marginBottom: 10, width: "100%" }}
        >
          <Grid item xs={10} className={classes.gridItem}>
            <Typography
              className={classes.titleheading}
              variant="h5"
              color="primary"
              component="h2"
            >
              {entity.name}
            </Typography>
            <Typography
              className={classes.titlesubheading}
              variant="h6"
              color="secondary"
              component="h2"
            >
              {entity.description}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            {((entity.typeid === c.ENTITY_TYPE.Device &&
              EditDeviceDemographicsPermission) ||
              (entity.typeid === c.ENTITY_TYPE.Facility &&
                EditFacilityDemographicsPermission) ||
              (entity.typeid === c.ENTITY_TYPE.ValetArea &&
                EditValetAreaDemographicsPermission) ||
              (entity.typeid === c.ENTITY_TYPE.FacilityGroup &&
                currentUser.isAdmin) ||
              (entity.typeid === c.ENTITY_TYPE.Area &&
                addAreaPermission)) && (
              <Button
                className={clsx(["btn-edit"])}
                data-name={entity.name}
                size="small"
                variant={isSmallScreen ? "text" : "outlined"}
                onClick={toggleShowEditor}
                startIcon={<EditIcon />}
                color="primary"
              >
                {isSmallScreen ? "" : "EDIT"}
              </Button>
            )}
          </Grid>
        </Grid>
        <Divider style={{ marginBottom: 20 }} />

        {EditSettingsPermission && (
          <FlagFormContextProvider entityId={entityId}>
            <SettingsForm
              entityID={entityId}
              entityType={entity.type}
              settingValues={{ ...commonSettings, ...typeSpecificSettings }}
              settingValuesParent={{ ...commonSettingsParent, ...typeSpecificSettingsParent }}
              parentEntityID={entity?.parententityid}
            />
          </FlagFormContextProvider>
        )}
      </div>

      <Drawer
        className={classes.editorDrawer}
        anchor="right"
        open={showEditDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {drawerContent(entity.typeid)}
      </Drawer>
    </>
  );
};

EntityDetailSettings.defaultProps = {
  entityId: undefined,
};
EntityDetailSettings.propTypes = {
  entityId: PropTypes.string,
};

export default EntityDetailSettings;
