import React, { useState, useCallback, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Measure from "react-measure";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  CardActions,
  Dialog,
  DialogContent,
  Grow,
  Typography,
  DialogActions,
  Drawer,
} from "@material-ui/core";
import { RibbonContainer, RightCornerLargeRibbon } from "react-ribbons";
import { createSelector } from "@reduxjs/toolkit";

import { useStyles } from "./styles";
import classNames from "classnames";
import clsx from "clsx";
import "animate.css";

import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { FindEntity, GetRawIDListWithScope } from "../../state/slices/entities";
import * as c from "../../constants";
import _ from "lodash";

//jg change refactor
import EntityExpandedView from "./Expanded";
import OccupancyMiniPanel from "../Occupancy/MiniPanel";
import EntityToiletSwirl from "../Entities/Modules/ToiletSwirl";
import EntityIconBar from "../Entities/Modules/EntityIconBar";
import AddEntity from "../Entities/Modules/EntityAddEntity";
import EntityQRCode from "../Entities/Modules/EntityQRCode";
import EntityProvisionButton from "../Entities/Modules/EntityProvisionButton";
import DeviceEditor from "../Forms/Onboarding/DeviceEditor";
import FacilityEditor from "../Forms/Onboarding/FacilityEditor";
import ValetAreaEditor from "../Forms/Onboarding/ValetAreaEditor";
import FacilityGroupEditor from "../Forms/Onboarding/FacilityGroupEditor";
import AreaEditor from "../Forms/Onboarding/AreaEditor";
import EntityDeleteButton from "../Entities/Modules/EntityDeleteButton";
import useHasPermissions from "../../hooks/useHasPermissions";
import EntityStatusBar from "../Entities/Modules/EntityStatusBar";
import DeviceActionMenu from "./DeviceActionMenu";
import { useFeatureFlag } from "../../hooks/useFeatureFlags";
import { FindEntityInScope, loadScope } from "../../state/slices/entityScope";
import { useListening } from "../../hooks/useDeviceListener/useDeviceListenerContext";
import {useCoreEntityContext} from "../../hooks/useCoreEntitySlice";
import {selectContextEntity, selectEntityById} from "../../state/slices/CoreEntity";

const DetailTransition = React.forwardRef(function Transition(props, ref) {
  return <Grow in={true} ref={ref} {...props} />;
});

// TODO: JonathanPayares need to refactor for orgSlice Changes keeping the same until that story
const EntityCard = ({ entityID, onResize, loadEntity, onDelete }) => {
  const { isBeingListened } = useListening();
  const useCoreEntitySlice = useCoreEntityContext();

  const classes = useStyles();
  const { hasPermissions } = useHasPermissions();
  const provisionDevicePermission = hasPermissions([
    "overview.devices.provisiondevice",
  ]);
  const AddFacilityPermission = hasPermissions(["onboarding.addfacilities"]);
  const AddDevicePermission = hasPermissions(
    ["overview.devices.adddevice"],
    false,
    entityID
  );
  const dispatch = useDispatch();
  const entityScopeList = useSelector((state) => state.entityScope.available);
  const contextId = useSelector((state) =>useCoreEntitySlice ? state.coreEntities.ContextID : state.entities.ContextID);
  const contextName = useSelector((state) => useCoreEntitySlice ? selectContextEntity(state)?.name : state.entities.Context.name);
  const facilityGroupID = useSelector(
    (state) => state.entityScope?.facilityGroupId
  );

  //This technically goes against documentatation. The reason that the code does the FindEntity in the input selector is its very common for Entity List to change reference due to DSDs but the specific entity does not change. If the entity does not change then the same object will be derived so we want this memoized. Tanner 3/20/2024
  const entitySelector = createSelector(
    (state) => useCoreEntitySlice ? selectEntityById(state, entityID) ?? null: FindEntity(state.entities?.EntityList ?? [], entityID),
    (entity) => {
      return {
        parentEntityID: entity?.parententityid,
        entityID: entity?.entityid,
        name: entity?.name,
        typeID: entity?.typeid,
        hasChildren: entity?.children?.length > 0 ?? false,
        lotFull:
          _.find(entity?.settings ?? [], { name: "displaylotfull" })?.value ==
          "auto"
            ? entity?.state != null && entity?.state?.Occupancy?.Full == true //if auto, is occupancy full or not?
            : _.find(entity?.settings ?? [], { name: "displaylotfull" })
                ?.value == "on"
            ? true
            : false, //if manual, is it on or not?
        laneClosed:
          _.find(entity?.settings ?? [], { name: "laneclosedsign" })?.value ==
          "true",
        disabled: entity?.state?.Disabled ?? false,
        deviceMode:
          _.find(entity?.settings ?? [], { name: "devicemode" })?.value ?? null,
        reverseOffline: entity?.state?.ReversingLane?.ReverseOffline,
      };
    }
  );

  const entity = useSelector(entitySelector, shallowEqual);

  console.log("EntityCard", entity,entityID);
  const shouldSeeParentButtonSelector = createSelector(
    entitySelector,
    (state) => state?.entities?.EntityList,
    (state) => state?.user?.isAdmin,
    (entity, entityList, isAdmin) => {
      const parentList = GetRawIDListWithScope(entityList);
      if (entity.parentEntityID === null) return false;
      const parent = _.find(parentList, {
        entityid: entity.parentEntityID,
      });
      if (!parent) return false;
      if (parent.parententityid == null && !isAdmin) return false;
      return true;
    }
  );

  const parentButton = useSelector(shouldSeeParentButtonSelector);

  const kidsButton = entity.hasChildren;

  useEffect(() => {
    //Force rerender if user or entity or other stuff in the array changes
    return () => {};
  }, [entity.laneClosed, entity.lotFull, entity.disabled, entityID]);

  // (ノಠ益ಠ)ノ彡┻━┻
  //NEW NORMALIZED USESTATE
  const [isDetailModalOpen, setDetailModalToggle] = useState(false); //get this in the drawer
  const [drawerState, setDrawerState] = useState({
    open: false,
    contentType: "",
    anchor: "right",
  });

  const hotelIntegrationsName = "Hotel Integrations";
  const hotelIntegrations = useFeatureFlag(hotelIntegrationsName);
  const isDisabledByFeature = useCallback(() => {
    switch (entity.deviceMode) {
      case c.DEVICE_MODE.HOTEL_INTERFACE:
        return !hotelIntegrations;
    }
    return false;
  }, [entity.deviceMode, hotelIntegrations]);

  const getDisabledFeatureName = useCallback(() => {
    switch (entity.deviceMode) {
      case c.DEVICE_MODE.HOTEL_INTERFACE:
        return hotelIntegrations ? "" : hotelIntegrationsName;
    }
    return "";
  }, [entity.deviceMode, hotelIntegrations]);

  const renderEntityRemoved = () => {
    onDelete(entity);
  };

  const openDetailModal = () => {
    if (isDisabledByFeature()) return;
    setDetailModalToggle(true);
  };
  const closeDetailModal = () => {
    setDetailModalToggle(false);
  };
  const closeDrawer = () => {
    setDrawerState({
      open: false,
      contentType: "",
      anchor: drawerState.anchor,
    });
  };

  const selectionCallback = (typeName) => {
    setDrawerState({
      open: true,
      contentType: "add-" + typeName.toLowerCase(),
      anchor: "right",
    });
  };
  const getDrawerContent = (contentType) => {
    switch (contentType) {
      case "child-selector":
        return (
          <AddEntity
            entityID={entity.entityID}
            selectionCallback={selectionCallback}
          />
        );
      case "add-area":
        return (
          <AreaEditor
            entityID={null}
            parentEntityID={entity.entityID}
            mode="create"
            onCancel={() => {
              setDrawerState({
                open: false,
                contentType: "",
                anchor: drawerState.anchor,
              });
            }}
            onSubmitComplete={handleAddEntity}
            onDeleteComplete={handleDeleteEntity}
          />
        );
      case "add-facility":
        return (
          <FacilityEditor
            entityID={null}
            parentEntityID={entity.entityID}
            mode="create"
            onCancel={() => {
              setDrawerState({
                open: false,
                contentType: "",
                anchor: drawerState.anchor,
              });
            }}
            onSubmitComplete={handleAddEntity}
            onDeleteComplete={handleDeleteEntity}
          />
        );
      case "add-valetarea":
        return (
          <ValetAreaEditor
            entityID={null}
            parentEntityID={entity.entityID}
            mode="create"
            onCancel={() => {
              setDrawerState({
                open: false,
                contentType: "",
                anchor: drawerState.anchor,
              });
            }}
            onSubmitComplete={handleAddEntity}
          />
        );
      case "add-facilitygroup":
        return (
          <FacilityGroupEditor
            entityID={null}
            parentEntityID={entity.entityID}
            mode="create"
            onCancel={() => {
              setDrawerState({
                open: false,
                contentType: "",
                anchor: drawerState.anchor,
              });
            }}
            onSubmitComplete={handleAddEntity}
            onDeleteComplete={handleDeleteEntity}
          />
        );
      case "add-device":
        return (
          <DeviceEditor
            entityID={null}
            parentEntityID={entity.entityID}
            parentEntityData={entity}
            mode="create"
            onCancel={closeDrawer}
            onSaveComplete={handleAddEntity}
            onDeleteComplete={handleDeleteEntity}
          />
        );
      case "provision":
        return (
          <EntityQRCode
            entityID={entity.entityID}
            onClose={closeDrawer}
          ></EntityQRCode>
        );
    }
  };
  const handleDeleteEntity = () => {
    closeDrawer();
    closeDetailModal();
    loadEntity(entity.entityID, facilityGroupID);
  };

  //THIS ONE CAN GO AWAY AFTER REDUX "EDIT" FAC IS IN PLAY
  const handleAddEntity = () => {
    closeDrawer();
    loadEntity(entity.entityID, facilityGroupID);

    // if the entity added has a parent in the entityScope, reload the current scope
    if (FindEntityInScope(entityScopeList, entity.entityID)) {
      dispatch(loadScope({ id: contextId, name: contextName }));
    }
  };

  const canRenderAddEntity = useCallback(
    (entityType) => {
      switch (entityType) {
        case c.ENTITY_TYPE.Tenant:
        case c.ENTITY_TYPE.FacilityGroup:
          return AddFacilityPermission;
        case c.ENTITY_TYPE.Facility:
          // NOTE: Once we are able to add areas, we'll need to readdress where this permission is checked.
          //       For now, we are only adding devices from a facility entity card, so this is fine
          return AddDevicePermission;
        case c.ENTITY_TYPE.Area:
          return true;
        case c.ENTITY_TYPE.Device:
          return false;
        default:
          return false;
      }
    },
    [AddFacilityPermission, AddDevicePermission]
  );

  if (entity === null) return <></>;

  return (
    <Measure
      client
      key="measure"
      onResize={(dimensions) => onResize(dimensions.client)}
    >
      {({ measureRef }) => (
        <>
          <RibbonContainer className={classes.ribbonator}>
            {entity.lotFull && (
              <RightCornerLargeRibbon
                backgroundColor="#ffcc00"
                color="brown"
                fontFamily="Arial"
              >
                LOT FULL
              </RightCornerLargeRibbon>
            )}
            {(entity.laneClosed === true || entity.reverseOffline === true) && (
              <RightCornerLargeRibbon
                backgroundColor="rgba(0,0,0,0.74)"
                color="#fafafa"
                fontFamily="Arial"
              >
                CLOSED
              </RightCornerLargeRibbon>
            )}
            <Card
              variant="elevation"
              className={classNames(
                classes.card,
                entity.disabled === true ||
                  isDisabledByFeature() ||
                  entity.reverseOffline === true
                  ? "disabled"
                  : "",
                entity.laneClosed === true || entity.reverseOffline === true
                  ? "closed"
                  : ""
              )}
              ref={measureRef}
              data-name={entity.name}
              data-id={entity.entityID}
              data-testid={entity.entityID}
            >
              <EntityStatusBar
                entityID={entity.entityID}
                disabled={isDisabledByFeature()}
                isBeingListened={isBeingListened(entityID)}
              />
              <CardContent
                className={clsx(["card-content"])}
                onClick={openDetailModal}
              >
                <EntityToiletSwirl
                  entityID={entity.entityID}
                  disabled={isDisabledByFeature()}
                  isBeingListened={isBeingListened(entityID)}
                />
                {entity.typeID == c.ENTITY_TYPE.Facility && (
                  <OccupancyMiniPanel entityId={entity.entityID} />
                )}
                {entity.typeID == c.ENTITY_TYPE.Device &&
                  (entity.laneClosed === true ||
                    entity.disabled === true ||
                    entity.reverseOffline === true) && (
                    <span className={clsx([classes.closedMsg, "closed"])}>
                      LANE CLOSED
                    </span>
                  )}
                {entity.typeID == c.ENTITY_TYPE.Device && entity.lotFull && (
                  <span className={clsx([classes.closedMsg, "full"])}>
                    LOT FULL
                  </span>
                )}
              </CardContent>
              <CardActions disableSpacing>
                {entity.typeID == c.ENTITY_TYPE.Device && (
                  <EntityIconBar
                    entityID={entity.entityID}
                    disabledFeature={getDisabledFeatureName()}
                    isBeingListened={isBeingListened(entityID)}
                  />
                )}

                {/* add entity button */}
                {canRenderAddEntity(entity.typeID) && (
                  <span style={{ marginLeft: "auto" }}>
                    <>
                      <IconButton
                        className={clsx(["btn-add-entity"])}
                        data-name={entity.name}
                        title="Add Entity"
                        aria-label="Add Entity"
                        onClick={() => {
                          setDrawerState({
                            open: true,
                            contentType: "child-selector",
                            anchor: "right",
                          });
                          //openAddModal(null);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </>
                  </span>
                )}
                {/* show parent */}
                {loadEntity != null && parentButton == true && (
                  <IconButton
                    className={clsx(["btn-expand-parent"])}
                    data-name={entity.name}
                    onClick={() => {
                      loadEntity(entity.parentEntityID);
                    }}
                    style={{
                      position: "absolute",
                      top: "-21px",
                      right: "calc(50% - 12px)",
                      padding: 0,
                      transform: "rotate(180deg)",
                    }}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                )}

                {/* show children */}
                {entity.typeID != c.ENTITY_TYPE.Device && kidsButton == true && (
                  <>
                    <IconButton
                      className={clsx(["btn-expand-children"])}
                      data-name={entity.name}
                      onClick={() => {
                        loadEntity(entity.entityID, facilityGroupID);
                      }}
                      style={{
                        position: "absolute",
                        bottom: "-21px",
                        right: "calc(50% - 12px)",
                        padding: 0,
                      }}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </>
                )}
              </CardActions>
            </Card>
          </RibbonContainer>

          {/* Detail Blowout */}
          <Dialog
            fullScreen={true}
            TransitionComponent={DetailTransition}
            scroll="paper"
            open={isDetailModalOpen}
            className={classNames("entity-card-dialog")}
          >
            <DialogContent dividers={true}>
              <EntityExpandedView entityId={entityID} />
            </DialogContent>
            <DialogActions style={{ display: "flex" }}>
            {entity.typeID == c.ENTITY_TYPE.Device &&
              provisionDevicePermission && (
                <EntityProvisionButton
                  entityID={entityID}
                  className={clsx(["btn-provision"])}
                  data-name={entity.name}
                  isBeingListened={isBeingListened(entityID)}
                />
              )}
            <EntityDeleteButton
              className={clsx(["btn-delete-entity"])}
              data-name={entity.name}
              entityID={entity.entityID}
              variant="outlined"
              size="small"
              color="secondary"
              onSuccess={renderEntityRemoved}
              isBeingListened={isBeingListened(entityID)}
            />
              <div style={{ flex: "1 0 0" }} />
              {entity.typeID === c.ENTITY_TYPE.Device && (
                <>
                  <DeviceActionMenu
                    entityID={entity.entityID}
                    entityName={entity.name}
                    deviceMode={entity.deviceMode}
                  />
                  <Typography>|</Typography>
                </>
              )}
              <Button
                onClick={closeDetailModal}
                color="primary"
                className={clsx(["btn-close-detail"])}
                data-name={entity.name}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/*drawerState drawer contents */}
          <Drawer
            style={{ minWidth: 400 }}
            anchor={drawerState.anchor}
            open={drawerState.open}
            onClose={closeDrawer}
          >
            <div
              style={{ padding: 20 }}
              className={clsx(`type_${drawerState.contentType}`)}
            >
              {getDrawerContent(drawerState.contentType)}
            </div>
          </Drawer>
        </>
      )}
    </Measure>
  );
};

EntityCard.defaultProps = {
  entityID: undefined,
  onResize: () => {},
  loadEntity: null,
};
EntityCard.propTypes = {
  entityID: PropTypes.string,
  onResize: PropTypes.func,
  loadEntity: PropTypes.func,
};

export default EntityCard;
